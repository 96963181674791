// Here you can add other styles

.border-faded {
    border: 1px solid rgba(29,29,29,.07);
}

@media only screen and (max-width: 450px) {
  .mobileHide {
    display: none;
  }
}
