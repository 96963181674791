input[type='date'].form-control {
  font-weight: bold;
}

.custom-select .bold {
  font-weight: bold;
  color: #5c6873;
}

input.form-control {
  font-weight: bold;
}

input::placeholder {
  font-weight: normal;
}
